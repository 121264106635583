import React from "react";
import { Helmet } from "react-helmet";

const MetaInfo = ({
  pageTitle,
  pageDescription,
  pageKeywords,
  ogTitle,
  ogUrl,
  ogDescription,
  ogType,
  ogSecureUrl,
  ogImage,
  TwTitle,
  TwDesc,
  TwImg,
  canonicalUrl,
}) => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <link rel="canonical" href={canonicalUrl} />

        {/* Open Graph Tags */}

        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:site_name" content="ND Health" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:secure_url" content={ogSecureUrl} />
        <meta property="og:image:height" content="630" />

        {/* Twiiter Tags */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ndhealth" />
        <meta name="twitter:title" content={TwTitle} />
        <meta name="twitter:description" content={TwDesc} />
        <meta name="twitter:image" content={TwImg}></meta>
      </Helmet>
    </div>
  );
};

export default MetaInfo;
