import "./App.css";
import Body from "./components/Pages/Body";
import Footer from "./components/Footer/Footer";
import Header from "./components/Pages/Header";
import Shop from "./components/Pages/Shop/Shop";
import ContactUs from "./components/Pages/ContactUs";
import WhatWeDo from "./components/Pages/WhatWeDo";
import Diabetes from "./components/Programs/Diabetes";
import PCOD from "./components/Programs/PCOD";
import Dyslipedimia from "./components/Programs/Dyslipedimia";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Thankyou from "./components/Pages/Thankyou";
import PrivacyAndPolicy from "./components/Privacy and Terms&Conditions/PrivacyAndPolicy";
import Terms from "./components/Privacy and Terms&Conditions/Terms";
import { createContext, useEffect, useState } from "react";
import Obesity from "./components/Programs/Obesity";
import LandingPage from "./components/LandingPage/LandingPage";
import ThankyouLand from "./components/LandingPage/ThankyouLand";
import Psoriasis from "./components/Programs/Psoriasis";
import StallPage from "./components/StallPage/StallPage";
import ThankyouStall from "./components/StallPage/ThankyouStall";
import AntiAging from "./components/Programs/AntiAging";
import NAFLD from "./components/Programs/NAFLD";
import Error from "./components/utils/Error";
import "./components/i18next";
import ShopHeader from "./components/Pages/Shop/ShopHeader";
import Cart from "./components/Pages/Cart";
import ItemCard from "./components/Pages/Shop/ItemCard";
import { CartProvider } from "./components/Pages/Shop/CartContext";
import { useTranslation } from "react-i18next";
import Blog from "./components/Pages/Blog";
import ShopFooter from "./components/Pages/Shop/ShopFooter";
import ShopLayout from "./components/Pages/Shop/Documents/ShopLayout";
import ShopShipping from "./components/Pages/Shop/Documents/ShopShipping";
import ShopCancel from "./components/Pages/Shop/Documents/ShopCancel";
import ShopTerms from "./components/Pages/Shop/Documents/ShopTerms";

// Context Provider

export const ContextProvider = createContext();

function App() {
  const [shimmerEffect, setShimmerEffect] = useState(true);
  const [loader, setLoader] = useState(true);
  const [quantity, setQuantity] = useState(0);

  const { t } = useTranslation();
  const { shopData } = t("shop");

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 4000);

    // Cleanup
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShimmerEffect(false);
      setLoader(true);
    }, 1500);

    return () => clearTimeout(timeId);
  }, []);

  return (
    <CartProvider>
      <ContextProvider.Provider
        value={{
          shimmerEffect,
          setShimmerEffect,
          loader,
          setLoader,
          quantity,
          setQuantity,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Error />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/thankyouland" element={<ThankyouLand />} />
            <Route path="/stallpage" element={<StallPage />} />
            <Route path="/thankyoustall" element={<ThankyouStall />} />

            <Route
              path="/"
              element={
                <div className="font-poppins">
                  <Header />
                  <Body />
                  <Footer />
                </div>
              }
            />

            {/****************    Programms Page     ****************/}

            <Route
              path="/programs/*"
              element={
                <div className="font-poppins">
                  <Header />
                  <Routes>
                    <Route
                      path="diabetes-reversal-program"
                      element={<Diabetes />}
                    />
                    <Route
                      path="dyslipidemia-treatment-program"
                      element={<Dyslipedimia />}
                    />
                    <Route path="pcod-management-program" element={<PCOD />} />
                    <Route
                      path="obesity-management-program"
                      element={<Obesity />}
                    />
                    <Route
                      path="psoriasis-treatment-program"
                      element={<Psoriasis />}
                    />
                    <Route path="anti-aging-program" element={<AntiAging />} />
                    <Route path="nafld-program" element={<NAFLD />} />
                  </Routes>
                  <Footer />
                </div>
              }
            />

            {/****************    Shop Page     ****************/}

            <Route
              path="/shop"
              element={<ShopLayout children={<Shop products={shopData} />} />}
            ></Route>
            <Route
              path="/shop/:name/:id"
              element={
                <ShopLayout children={<ItemCard products={shopData} />} />
              }
            ></Route>
            <Route
              path="/shop/terms"
              element={<ShopLayout children={<ShopTerms />} />}
            ></Route>
            <Route
              path="/shop/cancel"
              id=""
              element={<ShopLayout children={<ShopCancel />} />}
            ></Route>
            <Route
              path="/shop/shipping"
              element={<ShopLayout children={<ShopShipping />} />}
            ></Route>

            {/****************    Cart     ****************/}

            <Route
              path="/cart"
              element={<ShopLayout children={<Cart />} />}
            ></Route>

            {/****************    WhatWeDo     ****************/}

            <Route
              path="/whatwedo"
              element={
                <div className="font-poppins">
                  <Header />
                  <WhatWeDo />
                  <Footer />
                </div>
              }
            />

            {/****************    Blog     ****************/}

            <Route
              path="/nd-health-ai-blog"
              element={
                <div className="font-poppins">
                  <Header />
                  <Blog />
                  <Footer />
                </div>
              }
            />

            {/****************    Contact Us     ****************/}

            <Route
              path="/contactus"
              element={
                <div className="font-poppins">
                  <Header />
                  <ContactUs />
                  <Footer />
                </div>
              }
            />

            {/****************    Thank You     ****************/}

            <Route
              path="/thankyou"
              element={
                <div className="font-poppins">
                  <Header />
                  <Thankyou />
                  <Footer />
                </div>
              }
            />

            {/****************    Privacy and Policy     ****************/}

            <Route
              path="/privacy-policy"
              element={
                <div className="font-poppins">
                  <Header />
                  <PrivacyAndPolicy />
                  <Footer />
                </div>
              }
            />

            {/****************    Terms and Conditions     ****************/}

            <Route
              path="/terms-and-conditions"
              element={
                <div className="font-poppins">
                  <Header />
                  <Terms />
                  <Footer />
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </ContextProvider.Provider>
    </CartProvider>
  );
}
export default App;
