import { Link } from "react-router-dom";
import emptyCartImg from "../../Images/Shop/Empty.png";
import { scrollToTop } from "../utils/constant";
import { useTranslation } from "react-i18next";
import { useCart } from "./Shop/CartContext";

const Cart = () => {
  const { t } = useTranslation();
  const { cart, addToCart } = useCart();
  const { emptyText, shopMoreText, goToShopPageBtn, removeFromCart } =
    t("cart");

  console.log(cart);

  return (
    <div>
      {cart?.length === 0 ? (
        <div className="bg-lightBlue flex flex-col items-center gap-5 py-[5%] justify-center">
          <img src={emptyCartImg} className="w-96" alt="emptyCartImg" />
          <div className="flex flex-col gap-5 items-center xs:w-96">
            <h2 className="font-semibold uppercase">{emptyText}</h2>
            <p className="text-mediumGray">{shopMoreText} </p>
            <Link to={"/shop"}>
              <button onClick={scrollToTop} className="styleButton px-6 py-3">
                {goToShopPageBtn}
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className=" m-10">
          <div className="flex flex-col gap-5">
            <div className="flex gap-1 text-sm">
              <Link to={"/shop"}>
                <h3 className="text-mediumGray"> Home </h3>
              </Link>
              <h4> / Shopping cart</h4>
            </div>
            <h1 className="text-center py-5 text-2xl font-bold text-darkGray">
              Shopping Cart
            </h1>
          </div>
          <div className="flex items-start gap-5 justify-center">
            <div className="w-3/4 flex flex-col gap-5">
              <div className="flex items-start gap-5 justify-around">
                <h2>Image</h2>
                <h2>Product Name</h2>
                <h2>Quantity</h2>
                <h2>Price</h2>
                <h2>Total</h2>
                <h2>Remove</h2>
              </div>
              {cart.map((item) => {
                console.log(item);
                const { id, image_Url, content, oldPrice, newPrice, quantity } =
                  item;
                return (
                  <div
                    key={id}
                    className="flex border border-mediumGray rounded items-center justify-around"
                  >
                    <img src={image_Url} alt={content} className="w-28" />
                    <h2>{content}</h2>
                    <h3>{quantity}</h3>
                    <h4>₹ {oldPrice ? oldPrice : newPrice}</h4>
                    <h5>
                      ₹{" "}
                      {((oldPrice ? oldPrice : newPrice) * quantity).toFixed(2)}
                    </h5>
                    <button onClick={() => removeFromCart(id)}>x</button>
                  </div>
                );
              })}
            </div>
            <div className="w-1/4 flex flex-col gap-4 border border-mediumGray rounded p-3 font-medium">
              <h2 className="text-lg text-darkGray font-bold">Summary</h2>
              <div className="flex justify-between">
                <h2>Total Items</h2>
                <p>{cart?.length}</p>
              </div>

              <div className="flex justify-between">
                <h2>Subtotal</h2>
                <p>{cart?.length}</p>
              </div>

              <div className="flex justify-between border border-x-0 border-t-0 pb-5">
                <h2>Delivery charges</h2>
                <p>Free</p>
              </div>

              <div className="flex justify-between">
                <h2>GrandTotal</h2>
                <p>Free</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
