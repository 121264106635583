import Logo from "../../../Images/Logos/white Color side-1.png";
import twinHealthLogo from "../../../Images/Logos/Twin Logos/Twin white text Logo.png";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import SocialMedia from "../../Footer/SocialMedia";
import { scrollToTop } from "../../utils/constant";
import { useTranslation } from "react-i18next";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";

const NavLinks = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  const { t } = useTranslation();

  const { home, cart, quickLinks } = t("headerAndFooter");

  return (
    <div className="flex flex-col gap-3">
      <li className="md:text-base text-sm font-semibold">{quickLinks}</li>
      <li onClick={scrollToTop} className="hover:underline text-lightGray ">
        <NavLink to="/shop">{home}</NavLink>
      </li>
      <li onClick={scrollToTop} className="hover:underline text-lightGray ">
        <NavLink to="/cart">{cart}</NavLink>
      </li>
    </div>
  );
};

const ShopFooter = () => {
  const { t } = useTranslation();

  const { ndhealthDesc, otherLinks, terms, shipping, contactus, cancellation } =
    t("headerAndFooter");
  return (
    <footer className="flex flex-col gap-5 xs:gap-0 bg-mediumBlue py-2 text-sm md:text-base border-t border-mediumGray text-white w-full">
      <div className="flex sm:flex-row flex-col gap-5 justify-around py-2 pb-4 items-start px-2 md:px-0">
        {/*******   Logo & Social Media Links    ************/}

        <div className="flex flex-col items-start gap-3 xl:w-80 sm:w-64 xxs:w-60">
          <img
            src={Logo}
            loading="lazy"
            alt="logo"
            className="hover:scale-105 ease-out duration-500 lg:w-44 w-40 "
          />

          <p className="text-sm text-[11px]">{ndhealthDesc}</p>

          {/*********** Social Media Component **************/}
          <SocialMedia />
        </div>

        {/*******   NavLinks   ********/}

        <ul className="">
          <NavLinks />
        </ul>

        {/***********    Privacy and Terms    *****************/}
        <div className="flex flex-col gap-3 text-sm md:text-base ">
          <p className=" md:text-base text-sm font-semibold">{otherLinks}</p>

          <Link to="/shop/cancel" onClick={scrollToTop}>
            <p className="inline cursor-pointer hover:underline text-lightGray">
              {cancellation}
            </p>
          </Link>
          <Link to="/shop/terms" onClick={scrollToTop}>
            <p className="inline cursor-pointer hover:underline text-lightGray">
              {terms}
            </p>
          </Link>
          <Link to="/shop/shipping" onClick={scrollToTop}>
            <p className="inline cursor-pointer hover:underline text-lightGray">
              {shipping}
            </p>
          </Link>
        </div>

        {/***********    Address    *****************/}

        <div className="flex flex-col gap-3 text-sm md:text-base ">
          <p className="font-semibold ">{contactus}</p>
          <Link
            className="flex items-center gap-2 hover:underline text-lightGray"
            to={"mailto:support@ndhealth.ai"}
            target="_blank"
          >
            <IoMdMail />
            <span>support@ndhealth.ai</span>
          </Link>
          <Link
            className="flex items-center gap-2 hover:underline text-lightGray"
            to={"tel:+91 89776 17888"}
            target="_blank"
          >
            <FaPhoneAlt />
            <span>+91 89776 17888</span>
          </Link>
          <Link
            className="flex items-start gap-2 hover:underline text-lightGray"
            to={"https://maps.app.goo.gl/vWzhL57FEZYB7FoW8"}
            target="_blank"
          >
            <FaLocationArrow />
            <span className="md:w-64 xxs:w-60">
              Plot No.59, Road No.1, Brindavan Colony, Dr. A. S. Rao Nagar,
              ECIL, Secunderabad, Hyderabad – 500062
            </span>
          </Link>
        </div>

        {/************** Association **************/}

        {/* <div className="flex flex-row items-center lg:gap-4 gap-2 text-white">
          <p className="xxs:text-sm text-xs">{association}</p>

          <img
            src={twinHealthLogo}
            className="xs:w-24 w-20"
            alt="twinHealthLogo"
          />
        </div> */}
      </div>
      {/***********    Copy rights    ************/}
      <p
        dir="ltr"
        className="sm:text-base text-sm text-center py-3 border-t border-mediumGray"
      >
        &copy; ndhealth.ai All Rights Reserved.
      </p>
    </footer>
  );
};

export default ShopFooter;
