import { useParams } from "react-router";
import Mdata from "../../utils/JSON/MedicalData";
import ProductImage from "./SingleProduct/ProductImage";
import ProductDetails from "./SingleProduct/ProductDetails";
import ProductPrice from "./SingleProduct/ProductPrice";
import ProductDesc from "./SingleProduct/ProductDesc";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ItemCard = ({ products }) => {
  const paramsId = useParams();
  const { t } = useTranslation();

  // const { coming, tuned, homeBtn } = t("shop");
  const { shopData, forOrder, productDesc } = t("shop");

  const { orderText, orderNumber } = forOrder;

  const cardData = products?.find((data) => {
    return data?.id === parseInt(paramsId?.id);
  });

  return (
    <div>
      <div className="xl:mx-20 lg:mx-10 md:mx-5 xl:p-10 lg:p-5 p-2 ">
        <div className="flex py-5 gap-2 sm:text-base text-sm">
          <Link to={"/shop"}>
            <h2>Shop /</h2>
          </Link>
          <h2 className="text-mediumGray"> {cardData?.content}</h2>
        </div>
        <div className="flex flex-col sm:flex-row md:gap-0 gap-5 justify-center">
          <ProductImage cardData={cardData} />
          <ProductDetails cardData={cardData} />
          {/* <ProductPrice cardData={cardData} /> */}
        </div>
        {cardData?.productDescription && (
          <ProductDesc
            productDesc={productDesc}
            productDescription={cardData?.productDescription}
          />
        )}
      </div>
      <marquee className="w-full bg-Orange/50 py-1 bottom-0 fixed font-medium">
        <div className="flex">
          <h2>{orderText} </h2>
          <h3> {orderNumber}</h3>
        </div>
      </marquee>
    </div>
  );
};

export default ItemCard;
