import { useContext, useEffect } from "react";
import patternImg from "../../Images/contact.jpg";
import { MdOutlineMailOutline, MdLocationOn } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import twinHealthLogo from "../../Images/Logos/twin-health-black-logo.png";
import FormControl from "../Form/FormControl";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { ContextProvider } from "../../App";
import MyImage from "../utils/MyImage";
import MetaInfo from "../Meta/Meta-Info";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";
import { TbLocation } from "react-icons/tb";

const ContactUs = () => {
  const navigate = useNavigate();
  const { shimmerEffect } = useContext(ContextProvider);
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { t } = useTranslation();
  const { contactTitle, contactForm } = t("contactus");
  const { contactTitleStart, contacTitleMiddle, contacTitleLast } =
    contactTitle;
  const {
    contactFormTitle,
    username,
    phoneNo,
    email,
    city,
    category,
    checkbox,
  } = contactForm;

  const { usernameLabel, usernamePlaceholder, usernameError1, usernameError2 } =
    username;
  const {
    phoneNoLabel,
    phoneNoPlaceholder,
    phoneNoError1,
    phoneNoError2,
    phoneNoError3,
  } = phoneNo;
  const { emailLabel, emailPlaceholder, emailError1, emailError2 } = email;
  const { cityLabel, cityPlaceholder, cityError1, cityError2 } = city;
  const { categoryLabel, categoryPlaceholder, categoryError, categoryPrgs } =
    category;
  const {
    linkDiabetes,
    linkDyslipidemia,
    linkPCOD,
    linkObesity,
    linkPsoriasis,
    linkAntiAging,
    linkNAFLD,
  } = categoryPrgs;

  const { checkboxLabel, checkboxError } = checkbox;

  const emailAddress = "info@ndhealth.ai";
  const phoneNumber1 = "+91 89776 17888";
  const phoneNumber2 = "+91 88857 11171";

  const initialValues = {
    username: "",
    tel: "",
    email: "",
    city: "",
    category: "",
    time: "",
    agreetoterms: false,
  };

  const onSubmit = async (values, { resetForm }) => {
    validate(values);

    try {
      const response = await fetch("https://ndhealth.ai/contactForm.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      console.log(response);

      if (response.ok) {
        console.log("Form submitted successfully");
        // Handle success, e.g., show a success message or redirect
      } else {
        console.error("Form submission failed");
        // Handle failure, e.g., show an error message
      }
    } catch (error) {
      console.error("Error during form submission", error);
      // Handle error, e.g., show an error message
    }
    // console.log(values);
    navigate("/thankyou");
    resetForm();
  };

  // Username Validation

  const UsernameValid = (values, errors) => {
    if (values.username.trim().length === 0) {
      errors.username = usernameError1;
    } else if (
      values.username.trim().length < 4 ||
      values.username.trim().length >= 15
    ) {
      errors.username = usernameError2;
    }
  };

  // Email Validation

  const EmailValidation = (values, errors) => {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (values.email.trim() === "") {
      errors.email = emailError1;
    } else if (!emailPattern.test(values.email.trim())) {
      errors.email = emailError2;
    } else return;
  };

  // City Validation

  const cityValidation = (values, errors) => {
    if (values.city.trim().length === 0) {
      errors.city = cityError1;
    } else if (values.city.trim().length <= 2) {
      errors.city = cityError2;
    }
  };

  // Category Validation

  const categoryValid = (values, errors) => {
    if (values.category === "") {
      errors.category = categoryError;
    }
  };

  // Time Validation

  const timeValid = (values, errors) => {
    if (values.time === "") {
      errors.time = "required";
    }
  };

  // Agreetoterms Validation

  const agreetotermsValid = (values, errors) => {
    if (!values.agreetoterms) {
      errors.agreetoterms = checkboxError;
    }
  };

  const validate = (values) => {
    let errors = {};

    UsernameValid(values, errors);

    // Phone Number Validation

    var trimmedPhone = values.tel.startsWith("0")
      ? values.tel.slice(1)
      : values.tel;
    validateCommon(trimmedPhone, /^[6-9]\d{9}$/, phoneNoError3);

    function validateCommon(trimmedPhone, regex, errorMsgText) {
      if (trimmedPhone.length === 0) {
        errors.tel = phoneNoError1;
      } else if (trimmedPhone.length !== 10) {
        errors.tel = phoneNoError2;
      } else if (!regex.test(trimmedPhone)) {
        errors.tel = errorMsgText;
      } else return;
    }

    EmailValidation(values, errors);
    cityValidation(values, errors);
    categoryValid(values, errors);
    // timeValid(values, errors);
    agreetotermsValid(values, errors);

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  // console.log("formik values", formik.values);

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section>
      {/* Meta PageTitle and PageDescription */}

      {/* <meta property="og:title" content="Diabetes Reversal Program with AI | ND Health" />
<meta property="og:description" content="Manage diabetes with our AI-powered program. Reduce medication, lower blood sugar & lose weight. Get started today!" />
<meta property="og:site_name" content="ND Health" />
<meta property="og:url" content="https://ndhealth.ai/programs/diabetes-reversal-program" />
<meta property="og:image" content="https://ndhealth.ai/static/media/DiabetesImg.327e2b9491d5ce838274.jpg" />
<meta property="og:type" content="website" />
<meta property="og:image:secure_url" content="https://ndhealth.ai/static/media/DiabetesImg.327e2b9491d5ce838274.jpg" />
<meta property="og:image:width" content="1200" />
<meta property="og:image:height" content="630" />
 */}

      <MetaInfo
        pageTitle={"Contact ND Health | Connect for AI Healthcare Solutions"}
        pageDescription={
          "Reach out to ND Health for innovative AI healthcare solutions. Contact us today to discuss your needs and transform patient care!"
        }
        pageKeywords={"Contact ND Health, Get in touch with ND Health,"}
        ogUrl={"https://ndhealth.ai/contactus"}
        ogTitle={"Contact ND Health | Connect for AI Healthcare Solutions"}
        ogDescription={
          "Reach out to ND Health for innovative AI healthcare solutions. Contact us today to discuss your needs and transform patient care!"
        }
        ogImage={
          "https://ndhealth.ai/static/media/contact.b9292e6ad7cfada603f4.jpg"
        }
        canonicalUrl={"https://ndhealth.ai/contactus"}
        TwTitle={"Contact ND Health | Connect for AI Healthcare Solutions"}
        TwDesc={
          "Reach out to ND Health for innovative AI healthcare solutions. Contact us today to discuss your needs and transform patient care!"
        }
        TwImg={
          "https://ndhealth.ai/static/media/contact.b9292e6ad7cfada603f4.jpg"
        }
      />
      <div className="relative flex flex-col gap-10 min-h-screen">
        <img
          src={patternImg}
          className="w-full object-cover]"
          alt="patternImg"
        />
        {/*************************    Main Section    *******************************/}
        <div className="flex lg:justify-around lg:flex-row flex-col gap-10 items-center pb-10">
          <div
            data-aos="fade-right"
            className="max-w-[30rem] relative flex flex-col gap-10"
          >
            <h2 className="md:text-3xl xxs:text-xl xxs:leading-10 xxs:text-center sm:text-start md:leading-[3rem]">
              <span className="bg-Orange j p-1 mr-2 text-white font-semibold rounded-xl">
                {" "}
                {contactTitleStart}{" "}
              </span>
              {contacTitleMiddle} <span> {contacTitleLast} </span>
            </h2>

            <Link to={`mailto:${emailAddress}`} target="_blank">
              <div className="flex items-center gap-5">
                <p className="w-10 h-10 border border-lightGray flex items-center justify-center bg-lightGray rounded-full">
                  <MdOutlineMailOutline size={22} />
                </p>
                <p> {emailAddress}</p>
              </div>
            </Link>

            <div className="flex items-center gap-5">
              <p className="w-10 h-10 border border-lightGray flex items-center justify-center bg-lightGray rounded-full">
                <FiPhone size={22} />
              </p>
              <div>
                <Link to={`tel:${phoneNumber1}`}>
                  <p dir="ltr"> {phoneNumber1}</p>
                </Link>
                <Link to={`tel:${phoneNumber2}`}>
                  <p dir="ltr"> {phoneNumber2}</p>
                </Link>
              </div>
            </div>

            <Link
              to={"https://maps.app.goo.gl/vWzhL57FEZYB7FoW8"}
              target="_blank"
            >
              <div className="flex items-center gap-5">
                <p className="p-2 border border-lightGray flex items-center justify-center bg-lightGray rounded-full">
                  <TbLocation size={22} />
                </p>
                <p>
                  Plot No.59, Road No.1, Brindavan Colony, Dr. A. S. Rao Nagar,
                  ECIL, Secunderabad, Hyderabad – 500062
                </p>
              </div>
            </Link>
          </div>

          {/******************  Form Section ************************/}
          <div data-aos="fade-left">
            <div className="border-2 border-lightGray xs:p-5 bg-white rounded-2xl xxs:w-[21rem] xs:w-[30rem]">
              <h1 className="xs:pb-5 xxs:py-3 text-center xs:text-3xl xxs:text-xl font-semibold">
                {contactFormTitle}
              </h1>
              <form
                action="https://localhost/NewDirections-Health/contactForm.php"
                method="POST"
                onSubmit={formik.handleSubmit}
                className="flex flex-col items-center gap-5"
              >
                {/*******   Username  *********/}

                <div>
                  <label htmlFor="username" className="xxs:text-sm font-medium">
                    {usernameLabel} :
                  </label>
                  <br />
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="focus:outline-none xs:w-96 xxs:w-72 border border-lightGray p-2"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    placeholder={usernamePlaceholder}
                  />
                  {formik.errors.username ? (
                    <p className="text-error text-sm">
                      {formik.errors.username}
                    </p>
                  ) : null}
                </div>

                {/************   Mobile No  ***************/}

                <div>
                  <label htmlFor="tel" className="xxs:text-sm font-medium">
                    {phoneNoLabel} :
                  </label>
                  <br />
                  <input
                    className="focus:outline-none xs:w-96 xxs:w-72 border border-lightGray p-2"
                    type="tel"
                    id="tel"
                    name="tel"
                    onChange={formik.handleChange}
                    value={formik.values.tel}
                    placeholder={phoneNoPlaceholder}
                  />
                  {formik.errors.tel ? (
                    <p className="text-error text-sm">{formik.errors.tel}</p>
                  ) : null}
                </div>

                {/************   Email  ***************/}

                <div>
                  <label htmlFor="email" className="xxs:text-sm font-medium">
                    {emailLabel} :
                  </label>
                  <br />
                  <input
                    className="focus:outline-none xs:w-96 xxs:w-72 border border-lightGray p-2"
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder={emailPlaceholder}
                  />
                  {formik.errors.email ? (
                    <p className="text-error text-sm">{formik.errors.email}</p>
                  ) : null}
                </div>

                {/*******   City Name  *********/}

                <div>
                  <label htmlFor="city" className="xxs:text-sm font-medium">
                    {cityLabel} :
                  </label>
                  <br />
                  <input
                    className="focus:outline-none xs:w-96 xxs:w-72 border border-lightGray p-2"
                    type="text"
                    id="city"
                    name="city"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    placeholder={cityPlaceholder}
                  />
                  {formik.errors.city ? (
                    <p className="text-error text-sm">{formik.errors.city}</p>
                  ) : null}
                </div>

                {/* Select Dieseas */}

                <div>
                  <label htmlFor="category" className="xxs:text-sm font-medium">
                    {categoryLabel} :
                  </label>
                  <br />
                  <select
                    id="category"
                    name="category"
                    className="focus:outline-none xs:w-96 xxs:w-72 p-2 border border-lightGray j"
                    onChange={formik.handleChange}
                    value={formik.values.category}
                  >
                    <option value="">{categoryPlaceholder}</option>
                    <option value="diabetes">{linkDiabetes}</option>
                    <option value="dyslipedimia">{linkDyslipidemia}</option>

                    <option value="pcod">{linkPCOD}</option>
                    <option value="obesity">{linkObesity}</option>
                    <option value="psoriasis">{linkPsoriasis}</option>
                    <option value="anti-aging">{linkAntiAging}</option>
                    <option value="nafld">{linkNAFLD}</option>
                  </select>
                  {formik.errors.category ? (
                    <p className="text-error text-sm">
                      {formik.errors.category}
                    </p>
                  ) : null}
                </div>

                {/************* Language and Schedule Slot  **********************/}

                {/*******   Schedule  *********/}

                {/* <div>
                  <label
                    htmlFor="time"
                    className="mr-2 xxs:text-sm xs:text-base"
                  >
                    Select time slot*:
                  </label>
                  <br />
                  <select
                    id="time"
                    name="time"
                    onChange={formik.handleChange}
                    value={formik.values.time}
                    className="focus:outline-none xs:w-96 xxs:w-72 p-2 border border-lightGray j"
                  >
                    <option value="">Select</option>
                    <option value="9am-1pm">9 AM - 1 PM</option>
                    <option value="1pm-5pm">1 PM - 5 PM</option>
                    <option value="5pm-9pm">5 PM - 9 PM</option>
                  </select>
                  {formik.errors.time ? (
                    <p className="text-error text-sm">{formik.errors.time}</p>
                  ) : null}
                </div> */}

                {/************** Confirm Checkbox *******************/}

                <div className="xxs:px-5">
                  <input
                    className="xxs:text-sm font-medium"
                    type="checkbox"
                    id="agreetoterms"
                    name="agreetoterms"
                    checked={formik.values.agreetoterms}
                    onChange={formik.handleChange}
                  />
                  <label
                    htmlFor="agreetoterms"
                    className="pl-3 xxs:text-sm xs:text-base"
                  >
                    {checkboxLabel}
                  </label>
                  {formik.errors.agreetoterms ? (
                    <p className="text-error text-sm">
                      {formik.errors.agreetoterms}
                    </p>
                  ) : null}
                </div>

                {/************** Submit Button *******************/}

                <button
                  type="submit"
                  disabled={!formik.isValid}
                  className="styleButton px-10 py-3"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs; //426
