import { Link } from "react-router-dom";
import comingsoonImg from "../../../Images/comingsoon1.jpg";
import { scrollToTop } from "../../utils/constant";
import { useContext, useState } from "react";
import Shimmer from "../../utils/Shimmer Comp/Shimmer";
import { ContextProvider } from "../../../App";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";
import ProductItemList from "./ProductItemList";
import Medical from "../../utils/JSON/MedicalData";
import shopBannerImg from "../../../Images/Shop/Nd health shop banner-01.jpg";
import shopMobileViewBannerImg from "../../../Images/Shop/Nd health shop banner-02.jpg";
import MetaInfo from "../../Meta/Meta-Info";

const Shop = ({ products }) => {
  const { t } = useTranslation();

  // const { coming, tuned, homeBtn } = t("shop");
  const { formPlaceholder, quickView, categoryProduct, forOrder } = t("shop");

  const { orderText, orderNumber } = forOrder;

  const { shimmerEffect } = useContext(ContextProvider);
  const [searchText, setSearchText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [filterData, setFilterData] = useState(products);

  const getMedicalProducts = (e) => {
    e.preventDefault();
    const result = filterData.filter((data) => {
      return data.content.toLowerCase().includes(searchText.toLowerCase());
    });
    setFilterData(result);
    setDisplayText(searchText);
    setSearchText("");
  };

  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section className="relative min-h-screen">
      {/* Meta PageTitle and PageDescription */}
      <MetaInfo
        pageTitle={"Explore Premium AI Health Products for Optimal Well-being"}
        pageDescription={
          "Discover AI-powered health products for better well-being. Shop now and enhance your health with cutting-edge technology. Start your journey today!"
        }
        pageKeywords={
          "AI Health Products,Ai health products in india,  AI wellness products, AI-driven health technology"
        }
        canonicalUrl={"https://ndhealth.ai/shop"}
      />

      {/* <img
        src={comingsoonImg}
        className="w-full h-screen object-cover"
        alt="comingsoonImg"
      />
      <div className="absolute sm:top-1/4 lg:left-[5%] sm:left-[2%] bottom-[10%] left-[20%] flex flex-col sm:items-start items-center">
        <h1 className="lg:text-4xl md:text-3xl text-xl font-semibold">
          {coming}
        </h1>
        <p className="pt-2 md:pb-10 pb-3">{tuned}</p>
        <Link to="/">
          <button
            onClick={scrollToTop}
            className="bg-skyblue hover:text-white sm:px-5 sm:py-2 px-2 py-1 border border-skyblue font-medium rounded-md"
          >
            {homeBtn}
          </button>
        </Link>
      </div> */}
      <div className="bg-lightBlue xl:h-64 h-48  flex items-center justify-center">
        <img
          src={shopBannerImg}
          className="absolute xl:h-64 sm:h-48 w-full object-cover hidden md:block"
        />
        <img
          src={shopMobileViewBannerImg}
          className="absolute h-36 w-full object-cover md:hidden block"
        />
        {/*******************    Banner Section    ******************/}

        <form
          onSubmit={getMedicalProducts}
          className="flex w-fit items-center relative bg-white rounded"
        >
          <input
            type="text"
            value={searchText}
            placeholder={formPlaceholder}
            className="xs:w-96 xxs:w-72 rounded h-10 relative p-2 focus:outline-none text-sm font-semibold capitalize"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button type="submit" className="cursor-pointer rounded p-[0.43rem]">
            <CiSearch size={26} />
          </button>
        </form>
      </div>
      {/*******************    Card Section    ******************/}
      <div className="p-5">
        {!displayText ? (
          <h2 className="text-2xl p-2 font-semibold">{categoryProduct}</h2>
        ) : (
          <h2 className="text-2xl p-2 font-semibold text-mediumBlue">
            Searched results for{" "}
            <span className="capitalize">'{displayText}'</span>
          </h2>
        )}
        <div onClick={scrollToTop}>
          <ProductItemList quickView={quickView} filterData={filterData} />
        </div>
      </div>

      <marquee className="w-full bg-Orange/50 py-1 bottom-0 fixed font-medium">
        <div className="flex">
          <h2>{orderText} </h2>
          <h3> {orderNumber}</h3>
        </div>
      </marquee>
    </section>
  );
};

export default Shop;
